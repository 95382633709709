<template>
  <div class="setting-page">
    <el-form ref="ruleForm" :model="form" :rules="rules" label-width="200px">
      <el-alert title=" 配置密码策略用于修改密码核验" type="warning" />
      <el-form-item class="mt-20" label="规则类型：">
        <el-checkbox v-model="form.passwordRuleLarge" true-label="1" false-label="0">
          大写字母
        </el-checkbox>
        <el-checkbox v-model="form.passwordRuleSmall" true-label="1" false-label="0">
          小写字母
        </el-checkbox>
        <el-checkbox v-model="form.passwordRuleNumber" true-label="1" false-label="0">
          数字
        </el-checkbox>
        <el-checkbox v-model="form.passwordRuleSpecial" true-label="1" false-label="0">
          特殊字符
        </el-checkbox>
      </el-form-item>
      <el-form-item label="密码长度：">
        <el-input v-model="form.passwordLengthMin" class="min-input" size="small" />
        <span class="separator">~</span>
        <el-input v-model="form.passwordLengthMax" class="min-input" size="small" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="onSubmit('ruleForm')">
          保存设置
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getSystemInfo, updateSystemInfo } from '@/api/setting';

export default {
  data () {
    return {
      activeName: 'login',
      form: {
        sessionTime: '',
        passwordHistory: '',
        passwordErrorCount: '',
        passwordErrorLock: '',
        humanCheck: '0',
        passwordRuleLarge: '0',
        passwordRuleSmall: '0',
        passwordRuleNumber: '0',
        passwordRuleSpecial: '0',
        passwordLengthMin: '',
        passwordLengthMax: '',
        smtpHost: '',
        smtpPort: '',
        smtpSsl: '',
        smtpUsername: '',
        smtpPassword: '',
      },
      rules: {
        smtpHost: [
          { required: true, message: '请输入smtp主机地址', trigger: 'blur' },
        ],
        smtpPort: [
          { required: true, message: '请输入smtp主机端口', trigger: 'blur' },
        ],
        smtpSsl: [
          { required: true, message: '请选择启用的SSL方式', trigger: 'blur' },
        ],
        smtpUsername: [
          { required: true, message: '请输入发件名称', trigger: 'blur' },
        ],
        smtpPassword: [
          { required: true, message: '请输入发件密码', trigger: 'blur' },
        ],
      },
    };
  },
  mounted () {
    this.fetch();
  },
  methods: {
    fetch () {
      getSystemInfo().then((res) => {
        let list = res || [];
        if (list.length > 0) {
          list.map((item) => {
            this.form[item.key] = item.value;
          });
        }
      });
    },
    handleClick () { },
    onSubmit (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let k = [];
          let v = [];
          if (this.form.sessionTime) {
            k.push('sessionTime');
            v.push(this.form.sessionTime);
          }
          if (this.form.passwordHistory) {
            k.push('passwordHistory');
            v.push(this.form.passwordHistory);
          }
          if (this.form.passwordErrorCount) {
            k.push('passwordErrorCount');
            v.push(this.form.passwordErrorCount);
          }
          if (this.form.passwordErrorLock) {
            k.push('passwordErrorLock');
            v.push(this.form.passwordErrorLock);
          }
          if (this.form.humanCheck) {
            k.push('humanCheck');
            v.push(this.form.humanCheck);
          }
          if (this.form.passwordRuleLarge) {
            k.push('passwordRuleLarge');
            v.push(this.form.passwordRuleLarge);
          }
          if (this.form.passwordRuleSmall) {
            k.push('passwordRuleSmall');
            v.push(this.form.passwordRuleSmall);
          }
          if (this.form.passwordRuleNumber) {
            k.push('passwordRuleNumber');
            v.push(this.form.passwordRuleNumber);
          }
          if (this.form.passwordRuleSpecial) {
            k.push('passwordRuleSpecial');
            v.push(this.form.passwordRuleSpecial);
          }
          if (this.form.passwordLengthMin) {
            k.push('passwordLengthMin');
            v.push(this.form.passwordLengthMin);
          }
          if (this.form.passwordLengthMax) {
            k.push('passwordLengthMax');
            v.push(this.form.passwordLengthMax);
          }
          if (this.form.smtpHost) {
            k.push('smtpHost');
            v.push(this.form.smtpHost);
          }
          if (this.form.smtpPort) {
            k.push('smtpPort');
            v.push(this.form.smtpPort);
          }
          if (this.form.smtpSsl) {
            k.push('smtpSsl');
            v.push(this.form.smtpSsl);
          }
          if (this.form.smtpUsername) {
            k.push('smtpUsername');
            v.push(this.form.smtpUsername);
          }
          if (this.form.smtpPassword) {
            k.push('smtpPassword');
            v.push(this.form.smtpPassword);
          }
          updateSystemInfo({ k, v }).then(() => {
            this.$message.success('更新成功');
            this.fetch();
          });
        } else {
          this.$message.error('请完善配置信息');
          return false;
        }
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.setting-page {
  height: 100%;
  background: #fff;
  padding: 24px;
  min-height: 500px;
  .el-input--small {
    width: 300px;
    &.min-input {
      width: 120px;
    }
  }
  .separator {
    margin: 0 10px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .el-tabs {
    height: 390px;
  }
}
</style>